import React, { useState } from 'react';
import { Colors } from 'seedly-component-library';
import { ArticlePreviewList } from 'components/blog/home';
import useFetchInitialArticles from 'entity/blog/hooks/useFetchInitialArticles';
import { ArticleResponseWrapper } from 'entity/blog/blogTypes';
import ArticlePreviewListWrapper from '../ArticlePreviewListWrapper';
import Tabs from './Tabs';

interface OwnProps {
  recentArticles: ArticleResponseWrapper;
}

const TabbedArticleList = (props: OwnProps) => {
  const { recentArticles } = props;

  const popularBlogParam = {
    orderBy: 'views',
    page: 1,
    perPage: 5,
  };

  const [activeIndex, setActiveIndex] = useState(0);

  const { articles, isLoading } = useFetchInitialArticles({
    articleParams: popularBlogParam,
  });

  const onChangeTab = ({ index }) => {
    setActiveIndex(index);
  };

  const getTabRoutes = () => {
    return [
      {
        key: 'publishDate',
        title: 'Recent Articles',
        renderScene: () => {
          return <ArticlePreviewListWrapper featuredArticle={recentArticles} />;
        },
      },
      {
        key: 'views',
        title: "What's Popular",
        renderScene: () => {
          return (
            <ArticlePreviewList
              items={articles}
              loading={isLoading}
              endReached
            />
          );
        },
      },
    ];
  };

  return (
    <Tabs
      index={activeIndex}
      routes={getTabRoutes()}
      onIndexChange={onChangeTab}
      tabItemStyle={{
        fontSize: 14,
        height: 54,
      }}
      tabBarStyle={{
        backgroundColor: '#ffffff',
        marginBottom: 10,
      }}
      tabItemTheme={{
        activeColor: Colors.seedlyBlue,
        defaultColor: Colors.neutral5,
      }}
    />
  );
};

export default TabbedArticleList;
