import React from 'react';
import styled from 'styled-components';
import TabBar from './TabBar';

const Scene = styled.div`
  display: ${({ active }) => (active ? 'block' : 'none')};
`;

const Tabs = props => {
  const { renderTabBar, ...passProps } = props;
  const { routes, index } = passProps;
  return (
    <div>
      {renderTabBar ? renderTabBar(passProps) : <TabBar {...passProps} />}
      {routes.map((route, tabIndex) => {
        return (
          <Scene key={route.key} active={tabIndex === index}>
            {route.renderScene()}
          </Scene>
        );
      })}
    </div>
  );
};

export { TabBar };
export default Tabs;
