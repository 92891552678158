import { useCallback, useEffect, useState } from 'react';

// Countdown timer that supports seconds only.
const useTimer = (
  initialSeconds: number,
): { secondsLeft: number; resetTimer: () => void } => {
  const [secondsLeft, setSecondsLeft] = useState(initialSeconds);

  const resetTimer = useCallback(() => {
    setSecondsLeft(initialSeconds);
  }, [initialSeconds]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (secondsLeft > 0) {
        setSecondsLeft(secondsLeft - 1);
      } else {
        clearTimeout(timeout);
      }
    }, 1000);

    return () => clearTimeout(timeout);
  }, [secondsLeft]);

  return { secondsLeft, resetTimer };
};

export default useTimer;
