import React from 'react';
import ArticleItem from 'app/components/blog/ArticleItem';
import { Spinner } from 'app/seedly-component-library';
import ArticleLoader from 'app/components/blog/ArticleLoader';
import { Article } from 'app/entity/blog/blogTypes';

interface OwnProps {
  items: Article[];
  loadMoreButtonTitle?: string;
  onLoadMore?: () => void;
  loading?: boolean;
  spinner?: boolean;
  loadingMore?: boolean;
  endReached?: boolean;
  RenderWhenEmpty?: React.ReactNode;
}

const ArticlePreviewList = ({
  items = [],
  onLoadMore = () => {},
  loadMoreButtonTitle = 'Load more articles',
  loading = false,
  spinner = false,
  loadingMore = false,
  endReached = false,
  RenderWhenEmpty = null,
}: OwnProps) => {
  if (loading) {
    if (spinner) return <Spinner />;
    return <ArticleLoader />;
  }
  if (!items.length && RenderWhenEmpty) return <div>{RenderWhenEmpty}</div>;

  return (
    <div className="w-full">
      <ul>
        {items.map((article, index) => {
          return (
            <li className="mb-1" key={index}>
              <ArticleItem fullWidth toDisplayBookmark article={article} />
            </li>
          );
        })}
      </ul>
      {loadingMore && <ArticleLoader itemCount={2} />}
      {!endReached && !loadingMore && (
        <div className="p-3 lg:mt-3 lg:p-0">
          <div
            className="cursor-pointer bg-white h-[50px] flex justify-center items-center font-bold w-full rounded"
            onClick={onLoadMore}
          >
            {loadMoreButtonTitle}
          </div>
        </div>
      )}
    </div>
  );
};

export default ArticlePreviewList;
