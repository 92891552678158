import React, { useEffect } from 'react';
import Carousel from 'nuka-carousel';
import styled from 'styled-components';
import { media } from 'theme';
import useIsMobile from 'app/utils/hooks/useIsMobile';
import { ChevronLeft, ChevronRight } from 'react-feather';
import FeaturedArticleItem from '../FeaturedArticleItem';

const Wrapper = styled.div`
  width: 100%;
  background-color: #ffffff;
  margin-bottom: 10px;

  ${media.largeAndDown`
    margin-top: 10px;
  `}

  ${media.mediumAndDown`
    max-width: 100%;
    & .paging-item button {
      padding: 3px;
    }
  `}

  & .paging-item .paging-dot {
    background-color: #9aa1b3 !important;
  }

  & .paging-item.active .paging-dot {
    background-color: #40485a !important;
  }

  &:hover {
    .arrow-button-left,
    .arrow-button-right {
      opacity: 1;
    }
  }
`;

export const ArrowButton = styled.button`
  margin-bottom: 80px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  border: none;
  background-color: ${props => props.theme.colors.neutral7};
  opacity: 0;
  cursor: pointer;
  transition: opacity 0.3s ease-out;

  ${media.largeAndDown`
    opacity: 1;
  `}
`;

export const FeaturedArticleCarousel = ({ items }) => {
  // Selectors
  const isMobile = useIsMobile();

  // Effects
  useEffect(() => {
    // resize mobile carousel slide width to viewport width
    if (isMobile) {
      setTimeout(() => window.dispatchEvent(new Event('resize')), 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!items.length) return null;

  return (
    <Wrapper>
      <Carousel
        autoplay
        wrapAround
        autoplayInterval={3000}
        pauseOnHover={false}
        renderCenterLeftControls={({ previousSlide }) => {
          return (
            <ArrowButton
              className="arrow-button-left ml-3"
              onClick={previousSlide}
              style={{ left: '10px' }}
            >
              <ChevronLeft size={28} color="white" />
            </ArrowButton>
          );
        }}
        renderCenterRightControls={({ nextSlide }) => {
          return (
            <ArrowButton
              className="arrow-button-right mr-3"
              onClick={nextSlide}
            >
              <ChevronRight size={28} color="white" />
            </ArrowButton>
          );
        }}
        renderBottomCenterControls={() => null}
      >
        {items.slice(0, 5).map((item, index) => (
          <FeaturedArticleItem {...item} key={index} />
        ))}
      </Carousel>
    </Wrapper>
  );
};

export default FeaturedArticleCarousel;
