import React from 'react';
import he from 'he';

import BookmarkButton from 'app/components/post/BookmarkButton';
import { Colors } from 'app/seedly-component-library';
import Link from 'app/components/misc/Link';
import { Share2 as Share } from 'react-feather';
import { FacebookShareButton as ShareButton } from 'react-share';

import { SAVE_TYPE } from 'app/constants/app';
import Byline from '../Byline';

const FeaturedArticleItem = ({
  id,
  imageUrl,
  title,
  author,
  publishDate,
  link,
  slug,
}) => {
  return (
    <div className="min-h-[306px] lg:min-h-[460px] bg-white">
      <Link {...link}>
        <a>
          <img className="max-w-[100%]" alt="article" src={imageUrl} />
        </a>
      </Link>
      <div className="p-4">
        <div className="flex justify-between w-full mb-2">
          <h2 className="text-lg font-bold hover:text-blue-500">
            <Link {...link}>
              <a>{he.decode(title)}</a>
            </Link>
          </h2>
          <BookmarkButton contentId={id} slug={slug} type={SAVE_TYPE.ARTICLE} />
        </div>
        <div className="flex items-center gap-5">
          <Byline
            authorImageHidden
            author={author}
            publishDate={publishDate}
            style={{ color: Colors.neutral5 }}
          />
          <div id="fb-share-button">
            <ShareButton url={`https://blog.seedly.sg${link.as}`}>
              <div className="text-white flex bg-blue-500 rounded cursor-pointer w-[102px] px-2.5 py-0.5">
                <Share style={{ marginRight: '4px' }} />
                Facebook
              </div>
            </ShareButton>
          </div>
        </div>
      </div>
    </div>
  );
};

// FeaturedArticleItem.propTypes = {
//   imageUrl: PropTypes.string.isRequired,
//   title: PropTypes.string.isRequired,
//   author: PropTypes.object.isRequired,
//   publishDate: PropTypes.string.isRequired,
//   isPriority: PropTypes.bool,
// };

export default FeaturedArticleItem;
