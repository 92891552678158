import React from 'react';
import FeaturedArticleItem from 'app/components/blog/FeaturedArticleItem';
import { ChevronRight } from 'react-feather';
import Link from 'app/components/misc/Link';
import ArticlePreviewList from '../ArticlePreviewList';

const FeaturedCategorySection = props => {
  const {
    name,
    featuredArticle,
    articles = [],
    onLoadMore = () => {},
    loadingMore = false,
    endReached = false,
    link,
  } = props;

  return (
    <div>
      <div className="flex justify-between mb-6 py-4 px-4 lg:px-0 lg:py-0">
        <p className="lg:text-4xl text-2xl">{name}</p>
        <Link {...link}>
          <div className="flex gap-2 items-center">
            <span>View all</span>
            <ChevronRight size={24} />
          </div>
        </Link>
      </div>
      <div className="mb-3">
        {featuredArticle && <FeaturedArticleItem {...featuredArticle} />}
      </div>
      <ArticlePreviewList
        loadingMore={loadingMore}
        items={articles.slice(1, articles.length)}
        onLoadMore={onLoadMore}
        loadMoreButtonTitle={`View more ${name} articles`}
        endReached={endReached}
      />
    </div>
  );
};

// FeaturedCategorySection.propTypes = {
//   name: PropTypes.string.isRequired,
//   link: PropTypes.object.isRequired,
//   featuredArticle: PropTypes.object,
//   articles: PropTypes.arrayOf(PropTypes.object),
//   onLoadMore: PropTypes.func,
//   loadingMore: PropTypes.bool,
//   endReached: PropTypes.bool,
// };

export default FeaturedCategorySection;
