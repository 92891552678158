import React from 'react';
import { Clock } from 'react-feather';
import dayjs from 'dayjs';

import { Colors } from 'seedly-component-library';
import Link from 'components/misc/Link';
import formatTimeago from 'utils/formatTimeago';

const Byline = props => {
  const {
    authorImageHidden,
    author,
    publishDate,
    duration,
    timeagoDisabled,
  } = props;

  return (
    <div className="flex items-center h-4 gap-2 text-neutral-500">
      <Link {...author.link}>
        <a target="_blank">
          <div className="flex items-center gap-1.5">
            {!authorImageHidden && (
              <img
                className="h-4 w-4 rounded-full object-cover"
                alt="profile"
                loading="lazy"
                src={author.imageUrl}
              />
            )}
            <span>{author.name}</span>
          </div>
        </a>
      </Link>
      <p> ● </p>
      <time dateTime={new Date(publishDate).toISOString()}>
        {timeagoDisabled
          ? dayjs(new Date(publishDate)).format('DD MMM YYYY')
          : formatTimeago(publishDate, { cutoff: false })}
      </time>
      {duration && (
        <div className="flex pl-3 items-center justify-center gap-1 ">
          <Clock size={12} color={Colors.neutral5} />
          <span>{duration}</span>
        </div>
      )}
    </div>
  );
};

export default Byline;
