import apisauce from 'apisauce';
import baseApi, { ResponseData } from '../../api/base';
import { Pagination } from '../post/postTypes';
import {
  GetArticlesByCategoryParams,
  GetArticlesParams,
  SearchArticlesParams,
  ArticleResponseData,
  ArticleWtihCategoryResponseData,
  ArticleWtihAuthorResponseData,
  Article,
  ArticleFromWp,
} from './blogTypes';

const api = apisauce.create({
  baseURL: 'https://api-blog.seedly.sg/wp-json/',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 10000,
});

const blogApi = apisauce.create({
  baseURL: 'https://blog.seedly.sg/wp-json/',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 10000,
  withCredentials: true,
});

// eg: https://api-blog.seedly.sg/wp-json/seedly/v1/posts/how-much-are-you-giving-children-as-allowance
const getArticleFromWp = (slug: string) => {
  return api.get<Article>(encodeURI(`seedly/v1/posts/${slug}`));
};

// eg: https://api-blog.seedly.sg/wp-json/seedly/v1/posts/how-much-are-you-giving-children-as-allowance/related
const getRelatedArticles = (slug: string) => {
  return api.get<{ posts: Article[] }>(
    encodeURI(`seedly/v1/posts/${slug}/related`),
  );
};

const getArticlePreviewFromWp = ({
  id,
  wpnonce,
}: {
  id: number;
  wpnonce: string;
}) => {
  return blogApi.get<ArticleFromWp>(
    `wp/v2/posts/${id}`,
    {},
    {
      headers: {
        ...(wpnonce && wpnonce !== 'none' ? { 'X-WP-Nonce': wpnonce } : {}),
      },
      withCredentials: true,
    },
  );
};

const getArticles = ({
  page = 1,
  orderBy = 'publishDate',
  perPage = 10,
}: GetArticlesParams) => {
  return api.get<ArticleResponseData>(
    encodeURI(
      `seedly/v1/posts?page=${page}&orderBy=${orderBy}&perPage=${perPage}`,
    ),
  );
};

const getArticlesByCategory = ({
  slug,
  page = 1,
  perPage = 10,
  orderBy = 'publishDate',
}: GetArticlesByCategoryParams) => {
  return api.get<ArticleWtihCategoryResponseData>(
    encodeURI(
      `seedly/v1/posts/categories/${slug}?page=${page}&orderBy=${orderBy}&perPage=${perPage}`,
    ),
  );
};

const getArticlesByAuthor = ({
  slug,
  page = 1,
  perPage = 10,
  orderBy = 'publishDate',
}: GetArticlesByCategoryParams) => {
  return api.get<ArticleWtihAuthorResponseData>(
    encodeURI(
      `seedly/v1/posts/authors/${slug}?page=${page}&orderBy=${orderBy}&perPage=${perPage}`,
    ),
  );
};

const searchArticles = ({
  searchTerm,
  page = 1,
  perPage = 10,
}: SearchArticlesParams) => {
  return api.get<ArticleResponseData>(
    encodeURI(
      `seedly/v1/posts/search?search=${searchTerm}&page=${page}&perPage=${perPage}`,
    ),
  );
};

const getRecentArticle = (params: { page: number; perPage: number }) => {
  return baseApi.get<ResponseData<Article[], { pagination: Pagination }>>(
    '/v4/articles/recent',
    params,
  );
};

const getPopularArticle = (params: { page: number; perPage: number }) => {
  return baseApi.get<ResponseData<Article[], { pagination: Pagination }>>(
    '/v4/articles/popular',
    params,
  );
};

const getBookmarkedArticles = (params: {
  page: number;
  perPage: number;
  userSlug: string;
}) => {
  return baseApi.get<ResponseData<Article[], { pagination: Pagination }>>(
    `v4/users/${params.userSlug}/bookmarked_articles`,
    {
      page: params.page,
      perPage: params.perPage,
    },
  );
};

const getArticle = (slug: string) => {
  return baseApi.get<ResponseData<any>>(`/v4/articles/${slug}`);
};

const bookmarkArticle = (slug: string) => {
  return baseApi.put<ResponseData<Article>>(`v4/articles/${slug}/bookmark`);
};

const getSubmitArticlePage = () => {
  return api.get('wp/v2/pages', { slug: 'submit-article' });
};

export default {
  getArticle,
  getArticles,
  getArticleFromWp,
  getArticlePreviewFromWp,
  getRelatedArticles,
  getArticlesByCategory,
  getArticlesByAuthor,
  searchArticles,
  getRecentArticle,
  getPopularArticle,
  bookmarkArticle,
  getBookmarkedArticles,
  getSubmitArticlePage,
};
