import React from 'react';
import BlogLayout from 'components/blog/BlogLayout';
import FeaturedArticleCarousel from 'components/blog/FeaturedArticleCarousel';
import { ArticlePreviewList, TabbedArticleList } from 'components/blog/home';
import SubscriptionForm from 'components/blog/SubscriptionForm';
import styled from 'styled-components';
import { media } from 'theme';

import PinnedArticleSection from 'components/blog/PinnedArticleSection';
import useIsMobile from 'utils/hooks/useIsMobile';
import useGetArticles from 'entity/blog/hooks/useGetArticles';
import { ArticleResponseWrapper } from 'entity/blog/blogTypes';
import FeaturedCategoryWrapper from '../../components/blog/home/FeaturedCategoryWrapper';

const FeaturedCategoryList = styled.div`
  > *:first-child {
    margin-top: 44px;
  }

  > * {
    margin-bottom: 44px;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  ${media.largeAndDown`
    > *:first-child {
      margin-bottom: 22px;
    }
  `}
`;

const SectionItem = styled.div`
  margin-top: 22px;
`;

interface OwnProps {
  recentArticles: ArticleResponseWrapper;
  featuredArticles: ArticleResponseWrapper[];
}

const HomeContent = (props: OwnProps) => {
  const { recentArticles, featuredArticles } = props;
  const isMobile = useIsMobile();

  const { articles, isLoading, handleLoadMoreArticles } = useGetArticles(
    recentArticles,
  );

  return (
    <BlogLayout stickySidebarDisabled showGoogleAds>
      <FeaturedArticleCarousel items={articles.slice(0, 5)} />
      {isMobile ? (
        <>
          <TabbedArticleList recentArticles={recentArticles} />
          <SectionItem>
            <SubscriptionForm />
          </SectionItem>
          <SectionItem>
            <PinnedArticleSection />
          </SectionItem>
        </>
      ) : (
        <ArticlePreviewList
          items={articles}
          onLoadMore={handleLoadMoreArticles}
          loadingMore={isLoading}
          endReached={false}
        />
      )}
      <FeaturedCategoryList>
        {featuredArticles.map((item, key) => {
          return <FeaturedCategoryWrapper key={key} featuredArticle={item} />;
        })}
      </FeaturedCategoryList>
    </BlogLayout>
  );
};

export default HomeContent;
