import React from 'react';
import Button from 'seedly-component-library/button-new';
import buildConfig from 'app/utils/buildConfig';

interface FooterProps {
  currentPage: 'mobile' | 'code';
  isLoading: boolean;
  isOkButtonDisabled: boolean;
  onSendCode: () => void;
  onVerify: () => void;
  onBack: () => void;
}

const Footer = (props: FooterProps) => {
  const {
    isLoading,
    currentPage,
    isOkButtonDisabled,
    onSendCode,
    onVerify,
    onBack,
  } = props;

  let content: React.ReactNode = null;

  if (currentPage === 'mobile') {
    content = (
      <div className="flex flex-col items-center w-full gap-4">
        <p className="text-xs text-neutral-500 text-center">
          We will never call you or use your number without your consent. By
          continuing, you agree to the{' '}
          <a
            href={`${buildConfig.apiHost}/terms/`}
            rel="noreferrer"
            target="_blank"
          >
            Terms & Conditions
          </a>{' '}
          and{' '}
          <a
            rel="noreferrer"
            target="_blank"
            href={`${buildConfig.apiHost}/privacy/`}
          >
            Privacy Policy
          </a>
          .
        </p>
        <Button
          isLoading={isLoading}
          isBlock
          onClick={onSendCode}
          size="lg"
          disabled={isOkButtonDisabled}
        >
          Continue
        </Button>
      </div>
    );
  }

  if (currentPage === 'code') {
    content = (
      <div className="flex flex-col gap-2 w-full">
        <Button
          isLoading={isLoading}
          isBlock
          size="lg"
          disabled={isOkButtonDisabled}
          onClick={onVerify}
        >
          Verify
        </Button>
        <Button isBlock variant="outline" size="lg" onClick={onBack}>
          Back
        </Button>
      </div>
    );
  }

  return <div className="mx-4">{content}</div>;
};

export default Footer;
