import api, { ResponseData } from '../../api/base';
import { Post, BaseParam } from '../post/postTypes';

const getRecentFeeds = (params: BaseParam) => {
  return api.get<ResponseData<Post[]>>('v4/posts?sort_by_recents=true', params);
};

const getNewFeeds = (params: BaseParam) => {
  return api.get<ResponseData<Post[]>>('v4/posts?sort_by_new=true', params);
};

const getTrendingFeeds = (params: BaseParam) => {
  return api.get<ResponseData<Post[]>>('v4/posts/trending?days=7', params);
};

export default { getRecentFeeds, getNewFeeds, getTrendingFeeds };
