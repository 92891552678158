import React from 'react';
import Head from 'next/head';
import BaseHead, { blogPageMeta } from 'app/components/blog/BaseHead';
import he from 'he';

const { description, title, locale, url } = blogPageMeta;
const ldJson = {
  '@graph': [
    {
      '@type': 'WebPage',
      '@id': `${url}#webpage'`,
      url,
      inLanguage: locale,
      name: he.encode(title),
      isPartOf: {
        '@id': `${url}#website`,
      },
      about: {
        '@id': `${url}#organization`,
      },
      description: he.encode(description),
    },
  ],
};
const PageHead = () => {
  return (
    <Head>
      <BaseHead />
      <title>{title}</title>
      <link rel="canonical" href={url} />
      <meta name="description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={url} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:title" content={title} />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: BaseHead.buildLdJson(ldJson) }}
      />
    </Head>
  );
};

export default PageHead;
