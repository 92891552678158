import { useState, useEffect } from 'react';
import blogApi from 'entity/blog/blogApi';
import {
  GetArticlesParams,
  GetArticlesByCategoryParams,
  ArticleWithLink,
} from 'entity/blog/blogTypes';
import { addLinksToArticle } from 'entity/blog/blogUtils';

interface UseGetArticlesParams {
  // articles params that use to fetch article on client sides
  articleParams: GetArticlesParams | GetArticlesByCategoryParams;
}

// Use to initialize with articles by fetching on client side
const useFetchInitialArticles = ({ articleParams }: UseGetArticlesParams) => {
  const [articles, setArticles] = useState<ArticleWithLink[]>([]);
  const [loadingArticles, setLoadingArticles] = useState(false);

  useEffect(() => {
    async function fetchBlog() {
      setLoadingArticles(true);
      const blogResponse = await blogApi.getArticles({
        ...articleParams,
        page: 1,
      });
      const articlesWithLinks = blogResponse.ok
        ? blogResponse.data.posts.map(addLinksToArticle)
        : [];
      setArticles(articlesWithLinks);
      setLoadingArticles(false);
    }
    fetchBlog();
  }, []);

  return {
    articles,
    isLoading: loadingArticles,
  };
};

export default useFetchInitialArticles;
