import PropTypes from 'prop-types';

const routesType = PropTypes.arrayOf(
  PropTypes.shape({
    key: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    renderScene: PropTypes.func.isRequired,
  }).isRequired,
);

const tabItemThemeType = PropTypes.shape({
  defaultColor: PropTypes.string,
  activeColor: PropTypes.string,
});

export { routesType, tabItemThemeType };
