import api, { ResponseData } from '../../api/base';
import { Group } from './groupRedux';

const getGroup = (slug: string) => {
  return api.get<ResponseData<Group>>(`v4/groups/${slug}`);
};

const getGroups = () => {
  return api.get<ResponseData<Group[]>>('v4/groups', {});
};

const getMappedTopicToGroup = (topicSlug: string) => {
  return api.get<ResponseData<Group>>(`v4/groups/${topicSlug}/mapping`);
};

const followGroup = (id: number) => {
  return api.put<ResponseData<{}, { followed: boolean; followedAny: boolean }>>(
    `v4/groups/${id}/followers`,
    {},
  );
};

const followGroups = (groupIds: number[]) => {
  return api.post<ResponseData<Group[]>>('/v4/groups/follow_all', {
    group_ids: groupIds,
  });
};
const updateReadCount = (groupdId: number) => {
  return api.patch<ResponseData<{}>>(`/v4/groups/${groupdId}/checked`, {});
};

export default {
  getGroup,
  getGroups,
  getMappedTopicToGroup,
  followGroup,
  followGroups,
  updateReadCount,
};
