import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import { Colors } from 'app/seedly-component-library';
import { routesType, tabItemThemeType } from './types';

const TabBarContainer = styled.div`
  display: flex;
`;

const TabItem = styled.div`
  position: relative;
  padding: 12px 16px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${({ color }) => color};
`;

const TabBar = ({
  routes,
  index,
  onIndexChange,
  tabItemTheme,
  tabBarStyle,
  tabItemStyle,
}) => {
  return (
    <TabBarContainer style={tabBarStyle}>
      {routes.map((route, tabIndex) => {
        const active = tabIndex === index;
        const color = active
          ? tabItemTheme.activeColor
          : tabItemTheme.defaultColor;

        return (
          <TabItem
            style={tabItemStyle}
            color={color}
            key={route.key}
            active={active}
            onClick={() => onIndexChange({ index: tabIndex })}
          >
            {route.title}
          </TabItem>
        );
      })}
    </TabBarContainer>
  );
};

TabBar.propTypes = {
  routes: routesType,
  index: PropTypes.number.isRequired,
  onIndexChange: PropTypes.func,
  tabBarStyle: PropTypes.object,
  tabItemStyle: PropTypes.object,
  tabItemTheme: tabItemThemeType,
};

TabBar.defaultProps = {
  onIndexChange: noop,
  tabItemTheme: {
    defaultColor: Colors.neutral5,
    activeColor: Colors.neutral5,
  },
};

export default TabBar;
