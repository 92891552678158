import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { FormInput, Message } from '@component-lib';
import ConfirmationModal from 'components/post/ConfirmationModal';

import { RootState } from 'reducer';
import { closeVerifyNumberModal } from 'dispatcher/modalDispatcher';
import ProfileActions from 'reducer/profileRedux';
import authApi from 'entity/auth/authApi';
import useTimer from 'utils/hooks/useTimer';
import mixpanel, { trackedEvents } from 'utils/mixpanel';
import { XCircle } from 'react-feather';
import Dialog from 'seedly-component-library/dialog';

import Footer from './Footer';
import { validateMobileNumber, validateCode } from './helpers';

const VerifyMobileModal = () => {
  const dispatch = useDispatch();
  const isVerifyMobileOpen = useSelector(
    (state: RootState) => state.modal.isVerifyNumberModalOpen,
  );
  const modalMeta = useSelector(
    (state: RootState) => state.modal.verifyNumberModalMeta,
  );
  const userId = useSelector((state: RootState) => state.profile.user.id);
  const [isLoading, setIsLoading] = useState(false);

  const [page, setPage] = useState<'mobile' | 'code'>('mobile');

  const [mobileNumber, setMobileNumber] = useState('');
  const [verificationCode, setCode] = useState('');

  const [sendCodeError, setSendCodeError] = useState('');
  const [verifyError, setVerifyError] = useState('');

  const numberInputRef = useRef(null);
  const verifyInputRef = useRef(null);

  const { secondsLeft, resetTimer } = useTimer(30);

  useEffect(() => {
    if (page === 'code') {
      resetTimer();
    }
  }, [page, resetTimer]);

  const handleFormReset = () => {
    setMobileNumber('');
    setCode('');
    setSendCodeError('');
    setVerifyError('');
    setPage('mobile');
  };

  const handleModalClose = () => {
    dispatch(closeVerifyNumberModal());
    handleFormReset();
    mixpanel.track(trackedEvents.RewardsCollection, {
      param: 'skip_mobile_number_verification',
    });
  };

  const handleSendCode = async () => {
    // validate on FE first
    const isMobileNumberValid = validateMobileNumber(mobileNumber);
    let error = '';

    if (isMobileNumberValid) {
      setIsLoading(true);
      const response = await authApi.mobileRequestCode(userId, mobileNumber);
      setIsLoading(false);

      if (!response.ok) {
        if (response.data.message) {
          error = response.data.message;
        } else if (response.status === 422) {
          error = 'Please enter a valid mobile number.';
        } else {
          error = 'Something went wrong. Please try again.';
        }
      } else {
        setPage('code');
      }
    } else {
      error = 'Please enter a valid mobile number';
    }

    setSendCodeError(error);
  };

  const handleVerifyCode = async () => {
    const isCodeValid = validateCode(verificationCode);
    let error = '';

    if (isCodeValid) {
      setIsLoading(true);
      const response = await authApi.mobileVerifyCode(
        userId,
        mobileNumber,
        verificationCode,
      );
      setIsLoading(false);

      if (!response.ok) {
        if (response.status === 422) {
          error = 'Please enter a valid code.';
        } else {
          error = 'Something went wrong. Please try again.';
        }
      } else {
        handleModalClose();
        if (modalMeta?.isUpdating) {
          Message.success('Mobile number updated');
          dispatch(ProfileActions.updateUserMobileNumber(`+65${mobileNumber}`));
        } else {
          Message.success('Mobile number verified');
          dispatch(ProfileActions.onRegisterMobileSuccess(response.data?.data));
        }
      }
    } else {
      error = 'Please enter a valid code.';
    }

    setVerifyError(error);
  };

  let content: React.ReactNode = null;

  if (page === 'mobile') {
    content = (
      <>
        <div className="flex flex-col gap-2 w-full">
          <p className="text-xl font-bold">
            {modalMeta?.isUpdating
              ? 'Set up 2-step verification'
              : 'Let’s secure your account to keep your credits safe'}
          </p>
          <p>
            Don’t worry, we will never call you or use your number without your
            consent.
          </p>
        </div>
        <div className="flex flex-col  w-full">
          <div className="flex flex-col gap-2 w-full">
            <p>Your mobile number</p>
            <div className="flex gap-4 w-full items-center">
              <p>+65</p>
              <FormInput
                ref={numberInputRef}
                width="100%"
                type="tel"
                name="mobile-number"
                error={sendCodeError}
                value={mobileNumber}
                onChange={e => {
                  setMobileNumber(e.currentTarget.value);
                  setSendCodeError('');
                }}
                suffix={
                  mobileNumber.length > 0 && (
                    <XCircle
                      className="stroke-neutral-200 fill-neutral-500"
                      size={18}
                      onClick={() => {
                        numberInputRef.current.focus();
                        setMobileNumber('');
                      }}
                    />
                  )
                }
              />
            </div>
          </div>
        </div>
      </>
    );
  }

  if (page === 'code') {
    content = (
      <>
        <div className="flex flex-col gap-2 w-full">
          <p className="text-xl font-bold">Enter verification code</p>
          <p>
            Enter the 6-digit code we just texted to your mobile number,{' '}
            {mobileNumber}
          </p>
        </div>
        <div className="flex flex-col gap-2 w-full">
          <p>Authentication code</p>
          <FormInput
            ref={verifyInputRef}
            width="100%"
            type="number"
            name="verification-code"
            error={verifyError}
            value={verificationCode}
            onChange={e => {
              setCode(e.currentTarget.value);
              setVerifyError('');
            }}
            suffix={
              verificationCode.length > 0 && (
                <XCircle
                  size={18}
                  onClick={() => {
                    verifyInputRef.current.focus();
                    setCode('');
                  }}
                />
              )
            }
          />
          {secondsLeft === 0 ? (
            <span
              className="text-blue-500 cursor-pointer"
              onClick={() => {
                handleSendCode();
                resetTimer();
              }}
            >
              Resend code
            </span>
          ) : (
            <p className="text-neutral-500">
              Resend code in {secondsLeft} second{secondsLeft > 1 && 's'}
            </p>
          )}
        </div>
      </>
    );
  }

  return (
    <Dialog
      widthSize="md"
      isOpen={isVerifyMobileOpen}
      headerTitle={
        modalMeta?.isUpdating
          ? 'Update Mobile Number'
          : 'Verify your mobile number'
      }
      onCloseClick={() => {
        ConfirmationModal({
          title: 'Skip mobile number verification',
          content:
            "You're about to skip your mobile number verification. Do you wish to continue?",
          okText: 'Yes',
          cancelText: 'No',
        })
          .then(handleModalClose)
          .catch(() => {
            // noop to avoid uncaught error on cancel
          });
      }}
    >
      <div className="flex flex-col gap-6 w-full min-h-[300px] lg:min-h-[525px]">
        {content}
      </div>
      <Footer
        currentPage={page}
        isLoading={isLoading}
        isOkButtonDisabled={!mobileNumber}
        onSendCode={handleSendCode}
        onVerify={handleVerifyCode}
        onBack={handleFormReset}
      />
    </Dialog>
  );
};

export default VerifyMobileModal;
