import api, { ResponseData } from '../../api/base';
import paramsSerializer from '../../utils/paramSerializer';
import {
  Post,
  GroupPostsParams,
  UserPostsParams,
  ProductPostParams,
  PostForm,
  BaseParam,
} from './postTypes';

const getPost = (postSlug: string) => {
  return api.get<ResponseData<Post>>(`v4/questions/${postSlug}`, {});
};

const getPinnedPost = () => {
  return api.get<ResponseData<Post>>('v4/posts/pinned_campaign_post');
};

const getGroupPosts = (params: GroupPostsParams) => {
  return api.get<ResponseData<Post[]>>('v4/groups/feed', params, {
    paramsSerializer,
  });
};

const getUserPosts = (params: UserPostsParams) => {
  const { slug } = params;
  return api.get<ResponseData<Post[]>>(`v4/users/${slug}/questions`, params, {
    paramsSerializer,
  });
};

const getUserCommentedPosts = (params: UserPostsParams) => {
  const { slug } = params;
  return api.get<ResponseData<Post[]>>(
    `/v4/users/${slug}/parent_comments`,
    params,
    {
      paramsSerializer,
    },
  );
};

const getBookmarkedPosts = (params: UserPostsParams) => {
  const { slug } = params;
  return api.get<ResponseData<Post[]>>(
    `v4/users/${slug}/bookmarked_questions`,
    params,
    {
      paramsSerializer,
    },
  );
};

const getProductPosts = (params: ProductPostParams) => {
  const { postsFilter } = params;

  return api.get<ResponseData<Post[]>>(
    `v4/product/item/posts/${postsFilter}`,
    params,
    {
      paramsSerializer,
    },
  );
};

const getPosts = (params: BaseParam) => {
  return api.get<ResponseData<Post[]>>('v4/posts', params, {
    paramsSerializer,
  });
};

const getTrendingPosts = (params: BaseParam) => {
  return api.get<ResponseData<Post[]>>('v4/questions/trending?days=7', params, {
    paramsSerializer,
  });
};

const createPost = (params: PostForm) => {
  return api.post('v4/questions', params);
};

const updatePost = (params: PostForm) => {
  const { id } = params;
  return api.patch(`v4/questions/${id}`, params);
};

const deletePost = (postId: number) => {
  return api.delete(`v4/questions/${postId}`);
};

const updatePostPageView = (postId: number) => {
  return api.patch(`v4/questions/${postId}/count`);
};

const followPost = ({ followableId }) => {
  return api.put(`v4/questions/${followableId}/followers`, {});
};

const votePost = (params: { id: number; vote_type: number }) => {
  const { id } = params;
  return api.patch(`v4/questions/${id}/vote`, params);
};

const bookmarkPost = (params: { id: number }) => {
  const { id } = params;
  return api.put(`v4/questions/${id}/bookmark`);
};

const pinPost = (id: number) => {
  return api.post(`v4/questions/${id}/pin`);
};

const unPinPost = (id: number) => {
  return api.post(`v4/questions/${id}/unpin`);
};

const getSeedlyEventTopic = (slug: string) => {
  return api.get(`/v4/question/topics/seedlyevent_only?slug=${slug}&per=60`);
};

const getLinkPreview = async (params = {}) => {
  const response = await api.post('/v4/link_preview', params);

  if (response.ok) {
    return response;
  }

  return Promise.reject(new Error('Failed to retrieve link preview'));
};

export default {
  getPost,
  getPinnedPost,
  getPosts,
  getGroupPosts,
  getUserPosts,
  getUserCommentedPosts,
  getProductPosts,
  getBookmarkedPosts,
  getTrendingPosts,
  createPost,
  updatePost,
  deletePost,
  votePost,
  bookmarkPost,
  updatePostPageView,
  followPost,
  pinPost,
  unPinPost,
  getLinkPreview,
  getSeedlyEventTopic,
};
