import React from 'react';

import useGetArticles from 'entity/blog/hooks/useGetArticles';
import { ArticleResponseWrapper } from 'entity/blog/blogTypes';
import ArticlePreviewList from '../ArticlePreviewList';

interface OwnProps {
  featuredArticle: ArticleResponseWrapper;
}

const ArticlePreviewListWrapper = (props: OwnProps) => {
  const { featuredArticle } = props;

  const {
    articles,
    isLoading,
    haveMorePage,
    handleLoadMoreArticles,
  } = useGetArticles({
    ...featuredArticle,
  });

  return (
    <ArticlePreviewList
      items={articles}
      onLoadMore={handleLoadMoreArticles}
      loading={false}
      endReached={!haveMorePage}
      loadingMore={isLoading}
    />
  );
};

export default ArticlePreviewListWrapper;
