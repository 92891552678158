import { Colors } from 'app/seedly-component-library';
import he from 'he';
import { imageUrl } from 'theme';

const name = "Seedly - Get Rich or Die Tryin'";
const description =
  "Singapore's leading personal finance guide. We aim to help Singaporeans make informed and educated financial decisions.";
const url = 'https://blog.seedly.sg/';
const sameAs = [
  'https://www.facebook.com/seedlysg/',
  'https://www.instagram.com/seedlysg/',
  'https://www.linkedin.com/company/10526330/',
  'https://twitter.com/seedlysg',
];
const ldJson = {
  '@context': 'https://schema.org',
  '@graph': [
    {
      '@type': 'Organization',
      '@id': `${url}#organization`,
      name: 'Seedly',
      url,
      sameAs,
      logo: {
        '@type': 'ImageObject',
        '@id': `${url}#logo`,
        url: imageUrl.seedlyLogo,
        width: 189,
        height: 65,
      },
      image: {
        '@id': `${url}#logo`,
      },
    },
    {
      '@type': 'WebSite',
      '@id': `${url}#website`,
      url,
      name: he.encode(name),
      publisher: {
        '@id': `${url}#organization`,
      },
      potentialAction: {
        '@type': 'SearchAction',
        target: `${url}?s={search_term_string}`,
        'query-input': 'required name=search_term_string',
      },
    },
  ],
};

export default {
  name,
  description,
  url,
  ldJson,
  sameAs,
  title: `${name} - ${description}`,
  themeColor: Colors.seedlyBlue,
  locale: 'en_US',
  publisher: 'https://www.facebook.com/seedlysg/',
  verificationCode: {
    ms: '7C5A454E5D4F04004E3FF3F9382C707A',
    google: 'p-w8gkCMDoDV7YJe3poXlT2-Hq1CBYy6wvOvyPjhBiQ',
    facebook: '178405999239190',
    yandex: '60680b53cd1ec76d',
  },
};
