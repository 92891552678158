import React from 'react';
import produce from 'immer';
import mergeWith from 'lodash/mergeWith';
import blogPageMeta from './blogPageMeta';

const {
  name,
  locale,
  themeColor,
  verificationCode: code,
  ldJson: baseLdJson,
} = blogPageMeta;

const mergeArray = (objValue, srcValue) => {
  if (Array.isArray(objValue)) {
    return objValue.concat(srcValue);
  }
};

const BaseHead = () => {
  return (
    <>
      <meta property="msvalidate.01" content={code.ms} />
      <meta property="google-site-verification" content={code.google} />
      <meta property="fb:app_id" content={code.facebook} />
      <meta property="yandex-verification" content={code.yandex} />
      <meta property="og:site_name" content={name} />
      <meta property="og:locale" content={locale} />
      <meta property="twitter:card" content="summary" />
      <meta name="theme-color" content={themeColor} />
    </>
  );
};

BaseHead.buildLdJson = ldJson => {
  const merged = produce(baseLdJson, draft => {
    mergeWith(draft, ldJson, mergeArray);
  });
  return JSON.stringify(merged);
};

export { blogPageMeta };
export default BaseHead;
