import React from 'react';

import useGetArticles from 'entity/blog/hooks/useGetArticles';
import blogApi from 'entity/blog/blogApi';
import { ArticleResponseWrapper } from 'entity/blog/blogTypes';
import FeaturedCategorySection from '../FeaturedCategorySection';

interface OwnProps {
  featuredArticle: ArticleResponseWrapper;
}

const FeaturedCategoryWrapper = (props: OwnProps) => {
  const { featuredArticle } = props;
  const { articleParams, name } = featuredArticle;

  const {
    articles,
    isLoading,
    haveMorePage,
    handleLoadMoreArticles,
  } = useGetArticles({
    ...featuredArticle,
    articleApi: blogApi.getArticlesByCategory,
  });

  if (articles.length === 0) {
    return null;
  }

  return (
    <FeaturedCategorySection
      name={name}
      featuredArticle={articles[0]}
      articles={articles}
      link={{
        href: `/blog/category?categorySlug=${articleParams.slug}`,
        as: `/category/${articleParams.slug}`,
      }}
      onLoadMore={handleLoadMoreArticles}
      endReached={!haveMorePage}
      loadingMore={isLoading}
    />
  );
};

export default FeaturedCategoryWrapper;
