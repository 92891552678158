import React from 'react';
import he from 'he';

import BookmarkButton from 'app/components/post/BookmarkButton';
import Link from 'app/components/misc/Link';

import { SAVE_TYPE } from 'app/constants/app';
import Byline from '../Byline';

const ArticleItem = props => {
  const { article, onClick = () => {}, toDisplayBookmark } = props;

  const {
    id,
    articleId,
    imageUrl,
    title,
    excerpt,
    author,
    publishDate,
    duration,
    link,
    slug,
    categories,
  } = article;

  const mainCategory = categories[0];

  return (
    <div className="bg-white lg:rounded flex flex-row-reverse lg:flex-row px-4 py-6 relative w-full">
      <div
        className="mt-8 lg:mt-0 flex flex-col justify-end"
        data-testid="article-item"
      >
        <Link {...link}>
          <a target="_blank" onClick={onClick}>
            <img
              className="min-w-[100px] w-[100px] lg:w-[289px] lg:min-w-[289px] h-[69px] lg:h-[152px] rounded bg-neutral-200 hover:opacity-90"
              alt="article"
              src={imageUrl}
              loading="lazy"
            />
          </a>
        </Link>
      </div>
      <div className="flex flex-col lg:ml-6 ml-0 mr-2 lg:mr-0">
        {mainCategory && mainCategory.link && (
          <Link as={mainCategory.link.as} href={mainCategory.link.href}>
            <a
              className="text-xs font-bold uppercase text-blue-500"
              target="_blank"
            >
              {he.decode(mainCategory.name)}
            </a>
          </Link>
        )}
        <h3 className="mt-3 mb-3">
          <Link {...link}>
            <a
              className="text-lg font-bold hover:text-blue-500 leading-6"
              target="_blank"
              onClick={onClick}
            >
              {he.decode(title)}
            </a>
          </Link>
        </h3>
        <div className="mb-5 desktop-only">
          <p dangerouslySetInnerHTML={{ __html: excerpt }} />
        </div>
        <Byline author={author} duration={duration} publishDate={publishDate} />
      </div>
      {toDisplayBookmark && (
        <div className="absolute lg:right-4 lg:top-5">
          <BookmarkButton
            contentId={articleId || id}
            slug={slug}
            type={SAVE_TYPE.ARTICLE}
          />
        </div>
      )}
    </div>
  );
};

export default ArticleItem;
