import { useState, useCallback, useEffect } from 'react';
import blogApi from 'entity/blog/blogApi';
import {
  ArticleApiResponse,
  GetArticlesParams,
  GetArticlesByCategoryParams,
  ArticleWithLink,
} from 'entity/blog/blogTypes';
import { addLinksToArticle } from 'entity/blog/blogUtils';

interface UseGetArticlesParams {
  // articles response from server side
  articleResponse?: ArticleApiResponse;
  // params that use to fetch more articles
  articleParams?: GetArticlesParams | GetArticlesByCategoryParams;
  // api that use to fetch more articles
  articleApi?: (params: any) => Promise<ArticleApiResponse>;
}

// Use to initialize with articles fetched from serversides
const useGetArticles = ({
  articleResponse,
  articleParams = {},
  articleApi,
}: UseGetArticlesParams) => {
  const initialArticle: ArticleWithLink[] = articleResponse?.ok
    ? articleResponse.data.posts.map(addLinksToArticle)
    : [];
  const initialMorePage = articleResponse?.ok
    ? articleResponse.data.totalPages > articleResponse.data.page
    : false;

  const [articles, setArticles] = useState(initialArticle);
  const [articlePage, setArticlePage] = useState(1);
  const [loadingMoreArticles, setLoadingMoreArticles] = useState(false);
  const [haveMorePage, setHaveMorePage] = useState(initialMorePage);

  const api = articleApi || blogApi.getArticles;

  useEffect(() => {
    const updatedArticles: ArticleWithLink[] = articleResponse?.ok
      ? articleResponse.data.posts.map(addLinksToArticle)
      : [];
    setArticles(updatedArticles);
  }, [articleResponse]);

  const handleLoadMoreArticles = useCallback(async () => {
    setLoadingMoreArticles(true);
    const blogResponse = await api({
      ...articleParams,
      page: articlePage + 1,
    });
    const articlesNextPage = blogResponse.ok
      ? blogResponse.data.posts.map(addLinksToArticle)
      : [];
    setLoadingMoreArticles(false);
    setArticlePage(blogResponse.ok ? articlePage + 1 : articlePage);
    setArticles([...articles, ...articlesNextPage]);
    setHaveMorePage(
      blogResponse.ok
        ? blogResponse.data.totalPages > blogResponse.data.page
        : true,
    );
  }, [articlePage, articles, articleParams, api]);

  const handleLoadArticlesByPage = useCallback(
    async ({ page, orderBy }: { page: number; orderBy?: string }) => {
      setLoadingMoreArticles(true);
      const blogResponse = await api({
        ...articleParams,
        page,
        orderBy,
      });
      const articlesNextPage = blogResponse.ok
        ? blogResponse.data.posts.map(addLinksToArticle)
        : [];
      setLoadingMoreArticles(false);
      setArticlePage(page);
      setArticles([...articlesNextPage]);
    },
    [articleParams, api],
  );

  const handleLoadArticles = useCallback(
    async (params: GetArticlesParams | GetArticlesByCategoryParams) => {
      setLoadingMoreArticles(true);
      const blogResponse = await api(params);
      const fetchedArticles = blogResponse.ok
        ? blogResponse.data.posts.map(addLinksToArticle)
        : [];
      setLoadingMoreArticles(false);
      setArticlePage(blogResponse.ok ? blogResponse.data.page : 1);
      setArticles([...fetchedArticles]);
    },
    [api],
  );

  return {
    articles,
    haveMorePage,
    currentPage: articlePage,
    isLoading: loadingMoreArticles,
    handleLoadMoreArticles,
    handleLoadArticles,
    handleLoadArticlesByPage,
  };
};

export default useGetArticles;
