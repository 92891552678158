/* eslint-disable @typescript-eslint/no-throw-literal */
import React from 'react';
import Page from 'components/misc/BasePage/Blog';
import BlogSubHeader from 'components/blog/BlogSubHeader';
import { Head } from 'components/blog/home';
import BlogContent from 'pageContents/BlogContent';
import ApiError from 'api/error';
import blogApi from 'entity/blog/blogApi';
import { ArticleResponseWrapper } from 'entity/blog/blogTypes';

interface OwnProps {
  recentArticles: ArticleResponseWrapper;
  featuredArticles: ArticleResponseWrapper[];
}

const Home = ({ recentArticles, featuredArticles }: OwnProps) => {
  return (
    <Page renderSubHeader={() => <BlogSubHeader />}>
      <Head />
      <BlogContent
        recentArticles={recentArticles}
        featuredArticles={featuredArticles}
      />
    </Page>
  );
};

export async function getStaticProps() {
  const recentParams = {
    orderBy: 'publishDate',
    page: 1,
    perPage: 10,
  };
  const financeParams = {
    orderBy: 'publishDate',
    slug: 'financial-lifestyle',
    page: 1,
    perPage: 5,
  };
  const investParams = {
    orderBy: 'publishDate',
    slug: 'investments',
    page: 1,
    perPage: 5,
  };

  const [
    recentResponse,
    financeResponse,
    investmentResponse,
  ] = await Promise.all([
    blogApi.getArticles(recentParams),
    blogApi.getArticlesByCategory(financeParams),
    blogApi.getArticlesByCategory(investParams),
  ]);

  if (!recentResponse.ok) {
    const apiError = new ApiError(recentResponse);
    throw apiError;
  }

  return {
    props: {
      recentArticles: {
        articleResponse: { ok: recentResponse.ok, data: recentResponse.data },
        articleParams: recentParams,
      },
      featuredArticles: [
        {
          articleResponse: {
            ok: financeResponse.ok,
            data: financeResponse.data,
          },
          articleParams: financeParams,
          name: 'Lifestyle',
        },
        {
          articleResponse: {
            ok: investmentResponse.ok,
            data: investmentResponse.data,
          },
          articleParams: investParams,
          name: 'Investments',
        },
      ],
    },
    revalidate: 60,
  };
}

export default Home;
