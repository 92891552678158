import React from 'react';
import ContentLoader from 'react-content-loader';
import styled from 'styled-components';
import { media } from 'theme';
import fill from 'lodash/fill';
import PropTypes from 'prop-types';
import { Colors } from 'app/seedly-component-library';

const DESKTOP_WIDTH = 660;
const DESKTOP_HEIGHT = 202;
const MOBILE_WIDTH = 375;
const MOBILE_HEIGHT = 134;

const DesktopContainer = styled.div`
  background-color: #ffffff;
  display: block;
  height: ${DESKTOP_HEIGHT}px;
  svg {
    height: ${DESKTOP_HEIGHT}px;
  }
  ${media.mediumAndDown`
    display: none;
  `}
`;

const MobileContainer = styled.div`
  background-color: #ffffff;
  display: none;
  height: ${MOBILE_HEIGHT}px;
  svg {
    height: ${MOBILE_HEIGHT}px;
  }
  ${media.mediumAndDown`
    display: block;
  `}
`;

const ListItem = styled.li`
  margin-bottom: 5px;
  &:last-child {
    margin-bottom: 0px;
  }
`;

const Loader = () => {
  return (
    <>
      <DesktopContainer>
        <ContentLoader
          height={DESKTOP_HEIGHT}
          width={DESKTOP_WIDTH}
          ariaLabel="Loading articles"
          primaryColor={Colors.neutral2}
          secondaryColor={Colors.neutral4}
        >
          <rect x="16" y="24" rx="4" ry="4" width="290" height="154" />
          <rect x="330" y="24" rx="2" ry="2" width="128" height="20" />
          <rect x="330" y="55" rx="2" ry="2" width="310" height="32" />
          <rect x="330" y="98" rx="2" ry="2" width="310" height="32" />
          <rect x="330" y="158" rx="2" ry="2" width="222" height="20" />
        </ContentLoader>
      </DesktopContainer>
      <MobileContainer>
        <ContentLoader
          height={MOBILE_HEIGHT}
          width={MOBILE_WIDTH}
          ariaLabel="Loading articles"
          primaryColor={Colors.neutral2}
          secondaryColor={Colors.neutral4}
        >
          <rect x="18" y="16" rx="2" ry="2" width="80" height="12" />
          <rect x="18" y="38" rx="2" ry="2" width="216" height="20" />
          <rect x="18" y="60" rx="2" ry="2" width="216" height="20" />
          <rect x="18" y="102" rx="2" ry="2" width="216" height="12" />
          <rect x="260" y="50" rx="4" ry="4" width="98" height="64" />
        </ContentLoader>
      </MobileContainer>
    </>
  );
};

const ArticleLoader = ({ itemCount }) => {
  const data = fill(Array(itemCount), null);

  return (
    <ul>
      {data.map((_, index) => {
        return (
          <ListItem key={index}>
            <Loader />
          </ListItem>
        );
      })}
    </ul>
  );
};

ArticleLoader.propTypes = {
  itemCount: PropTypes.number,
};

ArticleLoader.defaultProps = {
  itemCount: 10,
};

export default ArticleLoader;
